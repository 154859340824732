import styled from 'styled-components';
import { Presets } from 'rete-react-plugin';
import { useTranslation } from 'react-i18next';
import {
    AccessTime,
    AppRegistration,
    Bookmark,
    Http,
    Input,
    ListAlt,
    LocationOn,
    Mms,
    MoreTime,
    PersonAdd,
    PersonRemove,
    Schema,
    Textsms,
    PlayArrow,
    Delete,
    Email,
    SmartToy,
    SmartButton,
    RadioButtonChecked,
    Outbound,
    MoveUp,
    BackupTable,
} from '@mui/icons-material';

const { Menu, Common, Search, Item, Subitems } = Presets.contextMenu;

export const CustomMenu = styled(Menu)`
    color: rgb(255, 255, 255);
    width: auto;
`;

export const CustomItem = styled(Item)`
    color: rgb(255, 255, 255);
    padding: 4px;
    border-bottom: 1px solid rgb(36 56 133) !important;
    background-color: rgb(36 56 133 / 63%) !important;
    cursor: pointer;
    width: 100%;
    position: relative;
    :hover {
        background-color: rgb(36 56 133 / 100%) !important;
    }
`;

export const CustomSubitems = styled(Subitems)`
    background: #0000003b;
`;

export const CustomSearch = styled(Search)`
    border-bottom: 1px solid rgb(36 56 133) !important;
    background: #0000003b;
`;

export const CustomCommon = styled(Common)`
    border-bottom: 1px solid rgb(36 56 133) !important;
    background-color: rgb(36 56 133 / 50%) !important;
    :hover {
        background-color: rgb(36 56 133 / 80%) !important;
    }
`;

/** Custom Items context menu */
export const CustomItemWithIcon = (props:any) => {
    const { t } = useTranslation();

    return (
        <CustomItem {...props.data} onClick={() => handlerConstextMenu(props.data)}>
            <div className='constextMenuCustomItem'>
                <span className='marginRightIcons'>{selectedIconNode(props.data.key)}</span>
                <span>{props.data.label === 'Delete' ? t('nodes.delete') : props.data.label}</span>
            </div>
        </CustomItem>
    );
};

/** call handler and after close context menu make hide*/
const handlerConstextMenu = (data:any) => {
    data.handler();
    const elements = document.querySelector('div[data-testid="context-menu"]');
    elements?.setAttribute('style', 'display: none');
};

/** Return icon by node */
const selectedIconNode = (labelNode: string) => {
    switch (labelNode) {
    case '0':// Message
        return <Textsms fontSize='small' />;
    case '1':// Input
        return <Input fontSize='small' />;
    case '2':// Switch
        return <ListAlt fontSize='small' />;
    case '3':// Media
        return <Mms fontSize='small' />;
    case '4':// Set Vars
        return <AppRegistration fontSize='small' />;
    case '5':// Subroutine
        return <Outbound fontSize='small' />;
    case '6':// Check Time
        return <AccessTime fontSize='small' />;
    case '7':// Invite User
        return <PersonAdd fontSize='small' />;
    case '8':// Leave
        return <PersonRemove fontSize='small' />;
    case '9':// Location
        return <LocationOn fontSize='small' />;
    case '10':// Delay
        return <MoreTime fontSize='small' />;
    case '11':// Distribute Chat
        return <Schema fontSize='small' />;
    case '12':// Start
        return <PlayArrow fontSize='small' />;
    case '13':// HttpRequest
        return <Http fontSize='small' />;
    case '14':// Email
        return <Email fontSize='small' />;
    case '15':// GptAssistant
        return <SmartToy fontSize='small' />;
    case 'delete':// Delete
        return <Delete fontSize='small' />;
    case '16':// Interactive
        return <SmartButton fontSize='small' />;
    case '17':// Add to StartSub
        return <RadioButtonChecked fontSize='small' />;
    case '18':// Goto
        return <MoveUp fontSize='small' />;
    case '19':// StartSection
        return <BackupTable fontSize='small' />;
    default:
        return <Bookmark fontSize='small' />;
    }
};