import React, { useState, useEffect } from 'react';
import {
    TextField,
    MenuItem,
    Grid,
    Typography,
} from '@mui/material';
import { InteractiveOption, InteractiveItems, InteractiveAction, InteractiveData } from '../../models/Interactive';
import { t } from 'i18next';
import InteractiveOptions from './InteractiveOptions';
import EmojiPickerComponent, { alignButton } from '../EmojiPcker';
import { clearMessage, getFormatTextAddEmoji } from '../../../util/util';
import TextEditor from '../TextEditor';
import { byMessage } from '../utils/configToolbarEditor';

const InteractiveFormMessage = (
    props: {
        interactiveData: InteractiveData,
        setInteractiveData: (interactiveData: InteractiveData) => void,
        interactiveTypeMedia: string,
        setInteractiveTypeMedia: (interactiveTypeMedia: string) => void,
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[],
        setInteractiveOptionsValues: (interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]) => void,
        bridgeOption: string,
        setBridgeOption: (bridgeOption: string) => void,
        interactiveTypeOption: string
    }
) => {
    const [isMedia, setIsMedia] = useState(props.interactiveData.interactive_message.content?.url ? true : false);
    const [messageInputHeader, setMessageInputHeader] = useState<HTMLInputElement|null>(null);
    const [messageInputCaption, setMessageInputCaption] = useState<HTMLInputElement|null>(null);
    const [messageInputTitle, setMessageInputTitle] = useState<HTMLInputElement|null>(null);
    const [messageInputGlobalButton, setMessageInputGlobalButton] = useState<HTMLInputElement|null>(null);

    useEffect(() => {
        setIsMedia(props.interactiveTypeMedia !== 'text');
    }, [props.interactiveTypeMedia]);

    const addEmoji = (emoji: string, fieldChange:string): void => {
        //add emoji to the message
        let templateInput;
        if(fieldChange == 'header'){
            templateInput = messageInputHeader?.id;
        }
        else if(fieldChange == 'caption'){
            templateInput = messageInputCaption?.id;
        }
        else if(fieldChange == 'title'){
            templateInput = messageInputTitle?.id;
        }
        else if(fieldChange == 'globalButton'){
            templateInput = messageInputGlobalButton?.id;
        }

        const text = document.getElementById(templateInput || '') as HTMLTextAreaElement;
        // value of the message
        const textValue = text.value;
        // cursor position
        const cursorPosition = text.selectionStart;
        text.value = getFormatTextAddEmoji(textValue, cursorPosition, emoji);
        handleChangeOption(text.value, fieldChange);
    };

    const handleChangeOption = (text:string, fieldChange:string) => {
        switch (fieldChange) {
        case 'header':
            props.setInteractiveData(
                {
                    ...props.interactiveData,
                    interactive_message: {
                        ...props.interactiveData.interactive_message,
                        content: {
                            url: props.interactiveData.interactive_message.content?.url || '',
                            type: props.interactiveData.interactive_message.content?.type || '',
                            header: text,
                            text: props.interactiveData.interactive_message.content?.text || '',
                            caption: props.interactiveData.interactive_message.content?.caption || '',
                        },
                    },
                }
            );
            break;
        case 'text':
            props.setInteractiveData(
                {
                    ...props.interactiveData,
                    interactive_message: {
                        ...props.interactiveData.interactive_message,
                        content: {
                            url: props.interactiveData.interactive_message.content?.url || '',
                            type: props.interactiveData.interactive_message.content?.type || '',
                            header: props.interactiveData.interactive_message.content?.header || '',
                            text: text,
                            caption: props.interactiveData.interactive_message.content?.caption || '',
                        },
                    },
                }
            );
            break;
        case 'caption':
            props.setInteractiveData(
                {
                    ...props.interactiveData,
                    interactive_message: {
                        ...props.interactiveData.interactive_message,
                        content: {
                            url: props.interactiveData.interactive_message.content?.url || '',
                            type: props.interactiveData.interactive_message.content?.type || '',
                            header: props.interactiveData.interactive_message.content?.header || '',
                            text: props.interactiveData.interactive_message.content?.text || '',
                            caption: text,
                        },
                    },
                }
            );
            break;
        case 'title':
            props.setInteractiveData(
                {
                    ...props.interactiveData,
                    interactive_message: {
                        ...props.interactiveData.interactive_message,
                        title: text,
                    },
                }
            );
            break;
        case 'body':
            props.setInteractiveData(
                {
                    ...props.interactiveData,
                    interactive_message: {
                        ...props.interactiveData.interactive_message,
                        body: text,
                    },
                }
            );
            break;
        case 'globalButton':
            props.setInteractiveData(
                {
                    ...props.interactiveData,
                    interactive_message: {
                        ...props.interactiveData.interactive_message,
                        global_buttons: [
                            {
                                type: 'text',
                                title:text,
                            },
                        ],
                    },
                }
            );
            break;
        default:
            break;
        }
    };

    const getMessageInputHeader = (input: HTMLInputElement | null) => {
        if (input) {
            setMessageInputHeader(input);
        }
    };

    const getMessageInputCaption = (input: HTMLInputElement | null) => {
        if (input) {
            setMessageInputCaption(input);
        }
    };

    const getMessageInputTitle = (input: HTMLInputElement | null) => {
        if (input) {
            setMessageInputTitle(input);
        }
    };

    const getMessageInputGlobalButton = (input: HTMLInputElement | null) => {
        if (input) {
            setMessageInputGlobalButton(input);
        }
    };

    if (props.bridgeOption === 'gupshup') {
        return <>
            <Grid container spacing={0} sx={{ mb: 1 }}>
                {props.interactiveTypeOption === 'button' && (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                label={t('interactive.type')}
                                id='interactive-type-option-form-message-type'
                                value={props.interactiveTypeMedia}
                                onChange={(e): void => props.setInteractiveTypeMedia(e.target.value)}
                                sx={{ m: 1, width: '96%' }}
                                multiline
                                size="small"
                                fullWidth
                                select
                            >
                                <MenuItem key={'interactiveTextType'} value='text'>
                                    {t('interactive.text')}
                                </MenuItem>
                                <MenuItem key={'interactiveImageType'} value='image'>
                                    {t('interactive.image')}
                                </MenuItem>
                                <MenuItem key={'interactiveVideoType'} value='video'>
                                    {t('interactive.video')}
                                </MenuItem>
                                <MenuItem key={'interactiveDocumentType'} value='document'>
                                    {t('interactive.document')}
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={t('interactive.url')}
                                id='interactive-type-option-form-message-url'
                                sx={{ mt: 1, ml: 1.4, width: '95%' }}
                                value={props.interactiveData.interactive_message.content?.url}
                                onChange={(e): void => props.setInteractiveData(
                                    {
                                        ...props.interactiveData,
                                        interactive_message: {
                                            ...props.interactiveData.interactive_message,
                                            content: {
                                                url: e.target.value,
                                                type: props.interactiveData.interactive_message.content?.type || '',
                                                header: props.interactiveData.interactive_message.content?.header || '',
                                                text: props.interactiveData.interactive_message.content?.text || '',
                                                caption: props.interactiveData.interactive_message.content?.caption || '',
                                            },
                                        },
                                    }
                                )}

                                multiline
                                size="small"
                                fullWidth
                                disabled={!isMedia}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <TextField
                                label={t('interactive.header')}
                                id='interactive-header-option'
                                sx={{ m: 1, width: '63ch' }}
                                value={props.interactiveData.interactive_message.content?.header}
                                onChange={(e): void => handleChangeOption(e.target.value, 'header')}
                                inputRef={getMessageInputHeader}
                                multiline
                                size="small"
                                fullWidth
                                inputProps={{ maxLength: 60 }}
                            >
                            </TextField>
                            <EmojiPickerComponent onEmojiSelect={(e:string) => addEmoji(e, 'header')} align={alignButton.middle} />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 1 }} container>
                            <Grid item xs={12}>
                                <Typography
                                    textAlign='left'
                                    variant='h6'
                                    sx={{ ml: 1, fontSize: '1rem', color: '#444444' }}
                                >
                                    {t('interactive.text')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextEditor
                                    value={clearMessage(props.interactiveData.interactive_message.content?.text)}
                                    toolbarOptions={byMessage}
                                    activeMarkdown={true}
                                    onChange={(value: string): void => handleChangeOption(value, 'text')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <TextField
                                label={t('interactive.caption')}
                                id='interactive-caption-option'
                                sx={{ m: 1, width: '63ch' }}
                                value={props.interactiveData.interactive_message.content?.caption}
                                onChange={(e): void => handleChangeOption(e.target.value, 'caption')}
                                required
                                inputRef={getMessageInputCaption}
                                multiline
                                size="small"
                                fullWidth
                                inputProps={{ maxLength: 60 }}
                            >
                            </TextField>
                            <EmojiPickerComponent onEmojiSelect={(e:string) => addEmoji(e, 'caption')} align={alignButton.middle} />
                        </Grid>
                        <InteractiveOptions
                            bridgeOption = {props.bridgeOption}
                            interactiveTypeOption = {props.interactiveTypeOption}
                            interactiveOptionsValues = {props.interactiveOptionsValues}
                            setInteractiveOptionsValues = {props.setInteractiveOptionsValues}
                        />
                    </>
                )}
                {props.interactiveTypeOption === 'list' && (
                    <>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <TextField
                                autoFocus
                                label={t('interactive.title')}
                                id='interactive-title-option'
                                sx={{ m: 1, width: '63ch' }}
                                value={props.interactiveData.interactive_message.title}
                                onChange={(e): void => handleChangeOption(e.target.value, 'title')}
                                multiline
                                inputRef={getMessageInputTitle}
                                required
                                size="small"
                                fullWidth
                                inputProps={{ maxLength: 60 }}
                            >
                            </TextField>
                            <EmojiPickerComponent onEmojiSelect={(e:string) => addEmoji(e, 'title')} align={alignButton.middle} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '5px',
                            }}
                            container
                        >
                            <Grid item xs={12}>
                                <Typography
                                    textAlign='left'
                                    variant='h6'
                                    sx={{ ml: 1, fontSize: '1rem', color: '#444444' }}
                                >
                                    {t('interactive.body')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextEditor
                                    value={clearMessage(props.interactiveData.interactive_message.body as string)}
                                    toolbarOptions={byMessage}
                                    activeMarkdown={true}
                                    onChange={(value: string): void => handleChangeOption(value, 'body')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <TextField
                                autoFocus
                                label={t('interactive.global_button')}
                                id='interactive-global-button-option'
                                sx={{ m: 1, width: '63ch' }}
                                value={
                                    props.interactiveData.interactive_message.global_buttons &&
                                    props.interactiveData.interactive_message.global_buttons[0].title
                                }
                                onChange={(e): void => handleChangeOption(e.target.value, 'globalButton')}
                                multiline
                                inputRef={getMessageInputGlobalButton}
                                required
                                size="small"
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                            >
                            </TextField>
                            <EmojiPickerComponent onEmojiSelect={(e:string) => addEmoji(e, 'globalButton')} align={alignButton.middle} />
                        </Grid>
                        <InteractiveOptions
                            bridgeOption = {props.bridgeOption}
                            interactiveTypeOption = {props.interactiveTypeOption}
                            interactiveOptionsValues = {props.interactiveOptionsValues}
                            setInteractiveOptionsValues = {props.setInteractiveOptionsValues}
                        />
                    </>
                )}
            </Grid>
        </>;
    } else if (props.bridgeOption === 'whatsapp-cloud') {
        return (
            <TextField
                autoFocus
                label={t('interactive.whatsapp_cloud_api_key')}
                id='interactive-whatsapp-cloud-api-key'
                value={props.bridgeOption}
                onChange={(e): void => props.setBridgeOption(e.target.value)}
                sx={{ m: 1, width: '98%' }}
                required
                multiline
                size="small"
            />
        );
    }

    return <></>;
};

export default InteractiveFormMessage;