import { NodeEditor } from 'rete';
import { Schemes } from './types';
import { ExportInputNode } from './models/Input';
import { ExportMessageNode } from './models/Message';
import { ExportSwitchNode, ExportCase, Case, ListItem } from './models/Switch';
import { ExportMediaData } from './models/Media';
import { ExportSetVarsNode } from './models/SetVars';
import { ExportSubroutineNode } from './models/Subroutine';
import { ExportCheckTimeNode } from './models/CheckTime';
import { ExportLeaveNode } from './models/Leave';
import { ExportLocationNode } from './models/Location';
import { ExportDelayNode } from './models/Delay';
import { ExportHttpRequestNode } from './models/HttpRequest';
import { ExportEmailNode } from './models/Email';
import { ExportDistributeChatJsonData } from './models/DistributeChat';
import { ExportGptAssistantNode } from './models/GptAssistant';
import { ExportInteractiveNode } from './models/Interactive';
import {
    InputModalControl,
    SwitchModalControl,
    MediaModalControl,
    CheckTimeModalControl,
    InviteUserModalControl,
    SetVarsModalControl,
    MessageModalControl,
    DistributeChatModalControl,
    HttpRequestModalControl,
    EmailModalControl,
    GptAssistantModalControl,
    InteractiveModalControl,
    LeaveModalControl,
    LocationModalControl,
    DelayModalControl,
    SubroutineModalControl,
    StartSubModalControl,
    GotoModalControl,
    StartSectionModalControl,
} from './controls';
import { ExportInviteUserNode } from './models/InviteUser';
import config from '../config.json';
import { mainParams, checkJinja2Syntaxis, slugify_text } from '../util/util';
import { ModalData, NodeData } from '../rete/types';

const get_output_connection = (node_id: string, editor?: NodeEditor<Schemes>): Array<{ label: string, o_connection: string | undefined }> | undefined => {
    const connections = editor?.getConnections();
    const outputs = connections?.filter((conn) => conn.source === node_id);
    if (outputs?.length === 0) return;
    const o_connections = outputs?.map((output) => {
        const output_node = editor?.getNode(output.target);
        const nodeModalInfo = (output_node?.controls!.modal as ModalData)?.options?.modalInfo;
        return {
            'label': output.sourceOutput,
            'o_connection': slugify_text(nodeModalInfo?.name ?? ''),
        };
    });
    return o_connections;
};

const serialize_object = (variables: ListItem[]): { [key: string]: string } => {
    const serialized_variables: { [key: string]: string } = {};
    variables.forEach((variable) => {
        serialized_variables[variable.key] = variable.value;
    });
    return serialized_variables;
};

const checkSaveConnection = (value: string): boolean => {
    // Check if the value of the connection is a jinja2 variable or the string 'finish'
    // It is used to determine if the connection should be processed and saved
    return checkJinja2Syntaxis(value) || value === 'finish';
};

const serialize_cases = (node_id: string, editor?: NodeEditor<Schemes>, cases?: Case[] | ExportCase[]): ExportCase[] => {
    const connections = get_output_connection(node_id, editor);

    const serialized_cases: ExportCase[] = [];
    if (cases) {
        cases?.forEach((item) => {
            let variables = {};
            const o_conn_is_jinja2 = checkSaveConnection(item.o_connection);
            const selected_connection = connections?.find((conn) => conn.label === item.id);

            if (item.variables && Array.isArray(item.variables)) {
                variables = serialize_object(item!.variables ?? []);
            }

            const case_serialized: ExportCase = {
                o_connection: o_conn_is_jinja2 ? item.o_connection : selected_connection?.o_connection ?? '',
                variables: variables,
            };
            
            checkJinja2Syntaxis(item.id || '') ? case_serialized.case = item.id : case_serialized.id = item.id;
            serialized_cases.push(case_serialized);
        });
    }else {
        connections?.forEach((node_case) => {
            serialized_cases.push({
                'id': node_case.label,
                'o_connection': node_case.o_connection ?? '',
            });
        });
    }

    return serialized_cases;
};

export function export_flow(module: string, editor?: NodeEditor<Schemes>): NodeData[] {
    const exportedNodes: NodeData[] = [];
    const nodes = editor?.getNodes();
    const connections = editor?.getConnections();

    /** Valid attachments Email */
    const validAttachments = (attachments: string[] | null): string[] | null => {
        if (!attachments) return null;
        const attachmentsArray = attachments.filter((attachment) => attachment !== '');
        return attachmentsArray.length > 0 ? attachmentsArray : null;
    };

    nodes?.forEach((node) => {
        if (node.label === 'Start') {
            const data: ExportMessageNode = {
                'id': 'start',
                'type': 'message',
                'name': 'start',
                'message_type': 'm.text',
                'text': '',
                'module': module,
            };
            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            };
            exportedNodes.push(data);
        } else if (node.label === 'Message') {
            const modalInfo = (node.controls!.modal as MessageModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportMessageNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'message',
                'name': modalInfo?.name ?? '',
                'message_type': modalInfo?.message_type ?? '',
                'text': modalInfo?.text ?? '',
                'module': module,
            };
            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            };
            exportedNodes.push(data);
        } else if (node.label === 'Input') {
            const modalInfo = (node.controls!.modal as InputModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportInputNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'input',
                'name': modalInfo?.name ?? '',
                'text': modalInfo?.text ?? '',
                'variable': modalInfo?.variable ?? '',
                'input_type': modalInfo?.input_type ?? '',
                'module': module,
                'cases': [],
            };

            // Check optional fields and add them to the data object
            if (modalInfo?.validation) data.validation = modalInfo?.validation;
            if (modalInfo?.validation_attempts) data.validation_attempts = parseInt(modalInfo?.validation_attempts);
            if (modalInfo!.middlewares) data.middlewares = modalInfo?.middlewares;
            if (modalInfo?.inactivity_options) {
                const inactivityOptions = modalInfo?.inactivity_options;
                data.inactivity_options = {
                    'chat_timeout': parseInt(String(inactivityOptions.chat_timeout)),
                    'warning_message': inactivityOptions?.warning_message ?? '',
                    'time_between_attempts': parseInt(String(inactivityOptions?.time_between_attempts)),
                    'attempts': parseInt(String(inactivityOptions?.attempts)),
                };
            }

            // Serialize cases and add them to the data object
            if (connections) {
                const cases = serialize_cases(node.id, editor, modalInfo!.cases);
                data.cases = [...cases!];
            }
            exportedNodes.push(data);
        } else if (node.label === 'Switch') {
            // Check optional fields and add them to the data object
            const modalInfo = (node.controls!.modal as SwitchModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportSwitchNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'name': modalInfo?.name ?? '',
                'type': 'switch',
                'module': module,
                'cases': [],
            };

            if (modalInfo?.validation) data.validation = modalInfo?.validation;
            if (modalInfo?.validation_attempts) data.validation_attempts = parseInt(modalInfo?.validation_attempts);
            if (connections) {
                const cases = serialize_cases(node.id, editor, modalInfo!.cases);
                data.cases = [...cases!];
            }
            exportedNodes.push(data);
        } else if (node.label === 'Media') {
            const modalInfo = (node.controls!.modal as MediaModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportMediaData = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'media',
                'name': modalInfo.name ?? '',
                'message_type': modalInfo.message_type ?? '',
                'text': modalInfo.text ?? '',
                'url': modalInfo.url ?? '',
                'module': module,
            };

            // Check optional fields and add them to the data object
            if (modalInfo?.info && modalInfo?.info.mimetype && modalInfo?.info.size && modalInfo?.info.height && modalInfo?.info.width) {
                data.info = {
                    'mimetype': modalInfo?.info.mimetype,
                    'size': parseInt(String(modalInfo?.info.size)),
                    'height': parseInt(String(modalInfo?.info.height)),
                    'width': parseInt(String(modalInfo?.info.width)),
                };
            }

            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            }
            exportedNodes.push(data);
        } else if (node.label === 'Set Vars') {
            const modalInfo = (node.controls!.modal as SetVarsModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportSetVarsNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'name': modalInfo.name,
                'type': 'set_vars',
                'variables': {
                    'unset': modalInfo?.unset ?? [],
                },
                'module': module,
            };

            if ((modalInfo?.set ?? []).length > 0) {
                const set_variables: { [key: string]: string | number } = {};
                modalInfo?.set.forEach((item) => {
                    set_variables[item.name] = item.value;
                });
                data.variables!.set = set_variables;
            }

            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            }
            exportedNodes.push(data);
        } else if (node.label === 'Subroutine') {
            const modalInfo = (node.controls!.modal as SubroutineModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportSubroutineNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'subroutine',
                'name': modalInfo?.name ?? '',
                'go_sub': modalInfo.go_sub,
                'module': module,
                'o_connection': '',
            };
            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            }
            exportedNodes.push(data);
        } else if (node.label === 'Check Time') {
            const modalInfo = (node.controls!.modal as CheckTimeModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportCheckTimeNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'check_time',
                'name': modalInfo?.name ?? '',
                'timezone': modalInfo?.timezone ?? '',
                'time_ranges': modalInfo?.time_ranges?.map(entry => entry['end'] ? `${entry['start']}-${entry['end']}` : entry['start']) ?? [],
                'days_of_week': modalInfo?.days_of_week?.map(entry => entry['end'] ? `${entry['start']}-${entry['end']}` : entry['start']) ?? [],
                'days_of_month': modalInfo?.days_of_month?.map(entry => entry['end'] ? `${entry['start']}-${entry['end']}` : entry['start']) ?? [],
                'months': modalInfo?.months?.map(entry => entry['end'] ? `${entry['start']}-${entry['end']}` : entry['start']) ?? [],
                'module': module,
                'cases': [],
            };

            if (connections) {
                const cases = serialize_cases(node.id, editor, modalInfo.cases);
                data.cases = [...cases!];
            }
            exportedNodes.push(data);
        } else if (node.label === 'Invite User') {
            const modalInfo = (node.controls!.modal as InviteUserModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportInviteUserNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'invite_user',
                'name': modalInfo.name,
                'timeout': parseInt(modalInfo.timeout),
                'invitee': modalInfo.invitee,
                'module': module,
                'cases': [],
            };

            // Serialize cases and add them to the data object
            if (connections) {
                const cases = serialize_cases(node.id, editor, modalInfo.cases);
                data.cases = [...cases!];
            }
            exportedNodes.push(data);
        } else if (node.label === 'Leave') {
            const modalInfo = (node.controls!.modal as LeaveModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportLeaveNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'leave',
                'name': modalInfo!.name || '',
                'reason': modalInfo!.reason || '',
                'module': module,
            };
            exportedNodes.push(data);
        } else if (node.label === 'Location') {
            const modalInfo = (node.controls!.modal as LocationModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportLocationNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'location',
                'name': modalInfo!.name ?? '',
                'longitude': modalInfo!.longitude,
                'latitude': modalInfo!.latitude,
                'module': module,
            };
            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            }
            exportedNodes.push(data);
        } else if (node.label === 'Delay') {
            const modalInfo = (node.controls!.modal as DelayModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportDelayNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'delay',
                'name': modalInfo!.name,
                'time': modalInfo!.time,
                'module': module,
            };
            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            }
            exportedNodes.push(data);
        } else if (node.label === 'Distribute Chat') {
            const [service, botMxid] = mainParams();
            const baseUrl = config.acd_as.base_url.replace('<domain>', service);
            const modalInfo = (node.controls!.modal as DistributeChatModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportHttpRequestNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'http_request',
                'subtype': 'distribute_chat',
                'name': modalInfo?.name ?? '',
                'method': 'POST',
                'url': `${baseUrl}/v1/cmd/acd`,
                'json': {
                    'customer_room_id': '{{ route.customer_room_id }}',
                    'destination': modalInfo?.destination ?? '',
                    'joined_message': modalInfo?.joined_message ?? '',
                    'put_enqueued_portal': modalInfo?.put_enqueued_portal ?? '',
                    'force_distribution': modalInfo?.force_distribution ?? '',
                },
                'variables': {
                    'distribution_status': 'status',
                },
                'headers': {
                    'Authorization': `Mxid ${botMxid}`,
                },
                'module': module,
                'cases': [{'id': '200', 'o_connection': ''}],
            };

            if (modalInfo?.queue_timeout && modalInfo?.queue_timeout > 0){
                (data.json as ExportDistributeChatJsonData).queue_timeout = modalInfo.queue_timeout.toString();
            }

            if (modalInfo?.campaign) {
                (data.json as ExportDistributeChatJsonData).campaign = modalInfo.campaign;
            }

            if (modalInfo?.subcampaign) {
                (data.json as ExportDistributeChatJsonData).subcampaign = modalInfo.subcampaign;
            }

            if (connections) {
                const incoming_cases = modalInfo.cases.map((item) => {
                    if (item.id === '202') item.id = 'enqueued';
                    return item;
                });
                const cases = serialize_cases(node.id, editor, incoming_cases).map((item) => {
                    if (item.id === 'enqueued') item.id = '202';
                    return item;
                });
                data.cases = [...cases!];
            }
            exportedNodes.push(data);
        } else if (node.label === 'HttpRequest') {
            const modalInfo = (node.controls!.modal as HttpRequestModalControl)?.options?.rawData;
            if (!modalInfo) return;
            const data: ExportHttpRequestNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': modalInfo.type,
                'name': modalInfo.name,
                'subtype': modalInfo.subtype ?? '',
                'middleware': modalInfo.middleware,
                'method': modalInfo.method,
                'url': modalInfo.url,
                'data': modalInfo.data,
                'json': modalInfo.json,
                'query_params': modalInfo.query_params,
                'headers': modalInfo.headers,
                'variables': modalInfo.variables,
                'module': module,
                'cases': [],
            };

            // Serialize cases and add them to the data object
            if (connections) {
                const cases = serialize_cases(node.id, editor, modalInfo!.cases);
                data.cases = cases;
            }
            exportedNodes.push(data);
        } else if (node.label === 'Email') {
            const modalInfo = (node.controls!.modal as EmailModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportEmailNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'email',
                'name': modalInfo.name,
                'server_id': modalInfo.server_id,
                'subject': modalInfo.subject,
                'recipients': modalInfo.recipients,
                'attachments': validAttachments(modalInfo.attachments ?? null),
                'text': modalInfo.text,
                'format': modalInfo.format,
                'encode_type': modalInfo.encode_type,
                'module': module,
            };
            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            }
            exportedNodes.push(data);
        } else if (node.label === 'GptAssistant') {
            const modalInfo = (node.controls!.modal as GptAssistantModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportGptAssistantNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'gpt_assistant',
                'name': modalInfo.name,
                'assistant_id': modalInfo.assistant_id,
                'api_key': modalInfo.api_key,
                'variable': modalInfo.variable,
                'module': module,
                'cases': [],
            };

            // Check optional fields and add them to the data object
            if (modalInfo?.instructions) data.instructions = modalInfo?.instructions;
            if (modalInfo?.initial_info) data.initial_info = modalInfo?.initial_info;
            if (modalInfo?.validation) data.validation = modalInfo?.validation;
            if (modalInfo?.validation_attempts) data.validation_attempts = parseInt(modalInfo?.validation_attempts);
            if (modalInfo?.inactivity_options) {
                const inactivityOptions = modalInfo?.inactivity_options;
                data.inactivity_options = {
                    'chat_timeout': parseInt(String(inactivityOptions.chat_timeout)),
                    'warning_message': inactivityOptions?.warning_message ?? '',
                    'time_between_attempts': parseInt(String(inactivityOptions?.time_between_attempts)),
                    'attempts': parseInt(String(inactivityOptions?.attempts)),
                };
            }

            // Serialize cases and add them to the data object
            if (connections) {
                const cases = serialize_cases(node.id, editor, modalInfo!.cases);
                data.cases = [...cases!];
            }

            exportedNodes.push(data);
        } else if (node.label === 'Interactive'){
            const modalInfo = (node.controls!.modal as InteractiveModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportInteractiveNode = {
                'id': slugify_text(modalInfo?.name ?? ''),
                'type': 'interactive_input',
                'name': modalInfo.name,
                'variable': modalInfo.variable,
                'interactive_message': modalInfo.interactive_message,
                'module': module,
                'cases': [],
            };

            if (modalInfo?.validation) data.validation = modalInfo?.validation;
            if (modalInfo?.validation_attempts) data.validation_attempts = parseInt(modalInfo?.validation_attempts);
            if (modalInfo?.inactivity_options) {
                const inactivityOptions = modalInfo?.inactivity_options;
                data.inactivity_options = {
                    'chat_timeout': parseInt(String(inactivityOptions.chat_timeout)),
                    'warning_message': inactivityOptions?.warning_message ?? '',
                    'time_between_attempts': parseInt(String(inactivityOptions?.time_between_attempts)),
                    'attempts': parseInt(String(inactivityOptions?.attempts)),
                };
            }

            if (connections) {
                const cases = serialize_cases(node.id, editor, modalInfo!.cases);
                data.cases = [...cases!];
            }
            exportedNodes.push(data);
        } else if (node.label === 'StartSub') {
            const modalInfo = (node.controls!.modal as StartSubModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportMessageNode = {
                'id': slugify_text(modalInfo.name),
                'type': 'message',
                'subtype': 'start_sub',
                'name': modalInfo.name,
                'message_type': 'm.text',
                'text': '',
                'module': module,
            };
            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            };
            exportedNodes.push(data);
        } else if (node.label === 'Goto') {
            const modalInfo = (node.controls!.modal as GotoModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportMessageNode = {
                'id': slugify_text(modalInfo.name),
                'type': 'message',
                'subtype': 'goto',
                'name': modalInfo.name,
                'message_type': 'm.text',
                'text': '',
                'module': module,
                'o_connection': modalInfo.o_connection,
            };
            exportedNodes.push(data);
        } else if (node.label === 'StartSection') {
            const modalInfo = (node.controls!.modal as StartSectionModalControl)?.options?.modalInfo;
            if (!modalInfo) return;
            const data: ExportMessageNode = {
                'id': slugify_text(modalInfo.name),
                'type': 'message',
                'subtype': 'start_section',
                'name': modalInfo.name,
                'message_type': 'm.text',
                'text': '',
                'module': module,
            };
            if (connections) {
                const o_connections = get_output_connection(node.id, editor);
                if (o_connections) {
                    data.o_connection = o_connections[0].o_connection;
                }
            };
            exportedNodes.push(data);
        }
    });

    return exportedNodes;
}
